<script lang="ts" setup>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { availableLocales } from '~/utils/lang'

// micro compiler
const props = defineProps({
  type: {
    type: String,
    default: 'dropdown-right-top',
  },
})

// state
const currentStyle = toRef(props, 'type')
const localeSetting = useState<string>('locale.setting')
const { t } = useLang()
</script>

<template>
  <div class="flex items-center">
    <Listbox
      v-if="currentStyle === 'dropdown-right-top'"
      v-model="localeSetting"
      as="div"
      class="relative flex items-center"
    >
      <ListboxLabel class="sr-only">Theme</ListboxLabel>
      <ListboxButton
        type="button"
        :title="$t('components.language_switcher.change_language')"
        class="transition-colors duration-300"
      >
        <span class="justify-center items-center flex">
          <div
            v-for="lang in availableLocales"
            :key="lang.iso"
            :value="lang.iso"
            :class="{
              'py-2 px-2 flex items-center cursor-pointer': true,
              'ease-in-out duration-150 text-black hover:bg-[#45994526] rounded-btn':
                localeSetting === lang.iso,
              hidden: localeSetting !== lang.iso,
            }"
          >
            <span class="text-sm mr-2">{{ lang.flag }}</span>
            <span class="text-base uppercase font-black text-base">{{
              lang.iso
            }}</span>
            <font-awesome-icon
              :icon="['fas', 'chevron-down']"
              class="ml-3 text-green"
            />
          </div>
        </span>
      </ListboxButton>
      <ListboxOptions
        class="p-1 absolute z-50 top-full right-0 outline-none bg-white rounded-lg ring-1 ring-gray-900/10 shadow-lg overflow-hidden w-38 py-1 text-sm text-gray-700 font-semibold dark:bg-gray-800 dark:ring-0 dark:highlight-white/5 dark:text-gray-300"
      >
        <ListboxOption
          v-for="lang in availableLocales"
          :key="lang.iso"
          :value="lang.iso"
          :class="{
            'py-2 px-2 flex items-center cursor-pointer': true,
            'text-green bg-[#45994526] rounded-md': localeSetting === lang.iso,
            'ease-in-out duration-150 hover:bg-soft-gray rounded-md':
              localeSetting !== lang.iso,
          }"
        >
          <span class="text-sm mr-2">
            {{ lang.flag }}
          </span>
          <span class="flex-1 truncate">
            {{ lang.name }}
            <span class="text-xs uppercase">({{ lang.iso }})</span>
          </span>
        </ListboxOption>
      </ListboxOptions>
    </Listbox>

    <select
      v-if="currentStyle === 'select-box'"
      v-model="localeSetting"
      class="w-full px-2 pr-3 py-2 outline-none rounded-btn border bg-transparent text-black"
    >
      <option
        v-for="lang in availableLocales"
        :key="lang.iso"
        :value="lang.iso"
        class="flex items-center space-x-2"
      >
        {{ lang.flag }} {{ lang.name }} ({{ lang.iso }})
      </option>
    </select>
  </div>
</template>
